/*
 * @Author: fengyanjing
 * @Date: 2020-06-05 17:40:15
 * @Description:实力瘦业绩 -- 团队达标折扣详情
 * @FilePath: /frontend/src/pages/rebate/shilishou/common/team-standard-bonus/index.js
 */

import CompRebateLayoutTwo from "@/components/rebate/comp-rebate-layout-two/index.vue";
import RebateTable from "@/components/rebate/rebate-table/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getStandardBonus } from "@/service/rebate/shilishouRebate";
import Rmb from "@/components/common/rmb/index.vue";

export default {
  name: "team-standard-bonus",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutTwo,
    RebateTable,
    Rmb,
  },
  data() {
    return {};
  },
  computed: {
    //  旗下创始人展示的行名
    unitColumns() {
      const columns = [
        {
          label: "创始人团队",
          key: "name",
          render(value) {
            return `${value}团队`;
          },
        },
        {
          label: "总体团购金额",
          isRmb: true,
          key: "allTeamTotal",
        },
        {
          label: "折扣系数",
          key: "discountCoefficientd",
          render(value) {
            return value * 100 + "%";
          },
        },
        {
          label: "团队达标折扣",
          isRmb: true,
          key: "teamOtherTotal",
        },
      ];
      return columns;
    },
    //  旗下合伙人展示的行名
    strategyColumns() {
      const columns = [
        {
          label: "合伙人团队",
          key: "name",
          render(value) {
            return `${value}团队`;
          },
        },
        {
          label: "总体团购金额",
          isRmb: true,
          key: "allTeamTotal",
        },
        {
          label: "折扣系数",
          key: "discountCoefficientd",
          render(value) {
            return value * 100 + "%";
          },
        },
        {
          label: "团队达标折扣",
          isRmb: true,
          key: "teamOtherTotal",
        },
      ];
      return columns;
    },
    //  旗下创始人的团队达标折扣列表
    uniteList() {
      if (this.rebateInfo) {
        return this.rebateInfo.uniteList || [];
      }
      return [];
    },
    //  旗下合伙人的团队达标明细列表
    strategyList() {
      if (this.rebateInfo) {
        return this.rebateInfo.strategyList || [];
      }
      return [];
    },
    // 旗下创始人的团队达标折扣 小计
    founderBonusSum() {
      if (this.rebateInfo) {
        const uniteList = this.rebateInfo.uniteList || [];
        return uniteList.reduce((sum, val) => {
          return Number(sum) + Number(val.teamOtherTotal);
        }, 0);
      }
      return "--";
    },
    //  旗下合伙人的团队达标明细 小计
    partnerBonusSum() {
      if (this.rebateInfo) {
        const strategyList = this.rebateInfo.strategyList || [];
        return strategyList.reduce((sum, val) => {
          return Number(sum) + Number(val.teamOtherTotal);
        }, 0);
      }
      return "--";
    },
  },
  methods: {
    //  初始化页面
    init() {
      const { startTime, endTime, agentId } = this;
      getStandardBonus({
        startTime,
        endTime,
        userId: agentId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },
  },
};
